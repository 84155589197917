const items = [
  { id: 1, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/614kzkELwmL._AC_UY327_FMwebp_QL65_.jpg', title: 'Nikon Camera', description: 'High-quality Nikon camera for professional photography.', price: 700 },
  { id: 2, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/71v9h7i6dqL._AC_UY327_FMwebp_QL65_.jpg', title: 'HP Laptop', description: 'Reliable HP laptop with powerful performance.', price: 600 },
  { id: 3, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/61OBw+XUiuL._AC_UY327_FMwebp_QL65_.jpg', title: 'Mobile Phone', description: 'Latest LG mobile phone with advanced features.', price: 500 },
  { id: 4, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/71goZuIha-L._AC_UY327_FMwebp_QL65_.jpg', title: 'Samsung Galaxy Ultra', description: 'Top-of-the-line Samsung Galaxy Ultra with cutting-edge technology.', price: 1200 },
  { id: 5, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/71Gci5++tFL._AC_UY327_FMwebp_QL65_.jpg', title: 'Touchpad Laptop', description: 'Stylish touchpad laptop with sleek design.', price: 550 },
  { id: 6, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/71zPRWYUyuL._AC_UY327_FMwebp_QL65_.jpg', title: 'Canon Camera', description: 'Canon camera perfect for both amateurs and professionals.', price: 800 },
  { id: 7, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/81Os1SDWpcL._AC_UY327_FMwebp_QL65_.jpg', title: 'iPhone 15 Pro max', description: 'Latest iPhone with all the new features.', price: 1100 },
  { id: 8, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/71idtiRXoSL._AC_UY327_FMwebp_QL65_.jpg', title: 'Canon PowerShot ELPH 360 Digital Camera', description: 'Canon camera with excellent image quality.', price: 750 },
  { id: 9, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/81lghf1fdzL._AC_UY327_FMwebp_QL65_.jpg', title: 'Sony Alpha ZV-E10L 24.2 Mega Pixel Interchangeable-Lens Mirrorless vlog Optical zoom Camera with 16-50mm Lens', description: 'High-performance Sony camera for stunning photos.', price: 700 },
  { id: 10, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/71FtdqEC1BL._AC_UY327_FMwebp_QL65_.jpg', title: 'ASUS Vivobook 15, Intel Core i5-12500H 12th Gen, 15.6" (39.62 cm) FHD, Thin and Light Laptop (16GB RAM/512GB/Win11/Office 2021/Silver/1.7 kg), X1502ZA-EJ542WS', description: 'Durable Asus laptop with great performance.', price: 650 },
  { id: 11, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/71vTksQ2PVL._AC_UY327_FMwebp_QL65_.jpg', title: 'itel Color Pro 5G | Color Changing Back | 6GB RAM+128GB ROM, Upto 12GB RAM ', description: 'Oppo Reno 10 5G with ultra-fast connectivity.', price: 900 },
  { id: 12, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/71lD7eGdW-L._AC_UY327_FMwebp_QL65_.jpg', title: 'Samsung Galaxy S23 Ultra 5G AI Smartphone (Green, 12GB, 256GB Storage)', description: 'Oppo Reno 10 with advanced features and sleek design.', price: 950 },
  { id: 13, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/81T5jRKDkvL._AC_UY327_FMwebp_QL65_.jpg', title: 'Dell 15 Thin & Light Laptop, 12th Gen Intel Core i5-1235U Processor, 8GB, 512GB SSD,', description: 'Various top-branded laptops for your needs.', price: 700 },
  { id: 14, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/61S-4Ri3Z2L._AC_UY327_FMwebp_QL65_.jpg', title: 'Canon EOS R6 Mark II 24.2 MP Mirrorless Camera-Body Only (Black)', description: 'Canon EOS 90D for high-resolution photography.', price: 850 },
  { id: 15, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/71657TiFeHL._AC_UY327_FMwebp_QL65_.jpg', title: 'iPhone 15', description: 'Newest iPhone 15 with latest technology.', price: 1300 },
  { id: 16, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/51pok18mRBL._AC_UY327_FMwebp_QL65_.jpg', title: 'Imou 3MP Smart CCTV Security WiFi Camera for Home, 360° Coverage', description: 'Nikon camera with an additional lens for versatile shooting.', price: 900 },
  { id: 17, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/71l1MOx3Q+L._AC_UY327_FMwebp_QL65_.jpg', title: 'ASUS TUF F15 Gaming Laptop, Intel Core i5-11400H 11th Gen', description: 'Reliable Asus laptop with advanced features.', price: 600 },
  { id: 18, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/81ndCd07RuL._AC_UY327_FMwebp_QL65_.jpg', title: 'Redmi 13 5g Black Diamond 6GB 128GB', description: 'Affordable Redmi phone with great features.', price: 400 },
  { id: 19, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/31Nk80-hUUL._AC_UY327_FMwebp_QL65_.jpg', title: 'CP PLUS 2MP Full HD Smart Wi-Fi CCTV Home Security Camera | 360°', description: 'Canon camera bundle with a sturdy tripod.', price: 950 },
  { id: 20, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/71v9h7i6dqL._AC_UY327_FMwebp_QL65_.jpg', title: 'HP Laptop 15s, 12th Gen Intel Core i3, 15.6-inch (39.6 cm), 8GB DDR4, 512GB SSD  ', description: 'HP laptop with excellent performance for all needs.', price: 580 },

  { id: 21, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/61RE1r+2pkL._AC_UY327_FMwebp_QL65_.jpg', title: 'vivo Y200 Pro 5G (Silk Green, 8GB RAM, 128GB Storage)', description: 'Feature-rich smartphone with advanced technology', price: 349 },
  { id: 22, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/51Evq4vl1XL._AC_UY327_FMwebp_QL65_.jpg', title: 'Nikon Z6 II Mirrorless Camera Z 24-70mm Lens', description: 'High-resolution Nikon DSLR camera for professionals', price: 799 },
  { id: 23, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/81leoJuYYaL._AC_UY327_FMwebp_QL65_.jpg', title: 'Lenovo IdeaPad Slim 3 12th Gen Intel Core i5-12450H 15.6" (39.6cm)', description: 'Reliable Lenovo laptop for everyday tasks', price: 699 },
  { id: 24, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/61VuVU94RnL._AC_UY327_FMwebp_QL65_.jpg', title: 'Apple iPhone 13 (128GB) - Midnight', description: 'Apple iPhone 13 with cutting-edge features', price: 999 },
  { id: 25, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/61ub7lJzRqL._AC_UY327_FMwebp_QL65_.jpg', title: 'Saramonic Vmic Mini Shotgun Microphone for Cameras & Mobile Devices ', description: 'Canon cameras with superior low-light performance', price: 899 },
  { id: 26, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/71jG+e7roXL._AC_UY327_FMwebp_QL65_.jpg', title: 'Apple MacBook Air Laptop M1 chip, 13.3-inch/33.74 cm Retina Display, 8GB RAM, 256GB SSD Storage', description: 'Apple MacBook with high-end specifications', price: 1299 },
  { id: 27, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/71eygiVHCuL._AC_UY327_FMwebp_QL65_.jpg', title: 'POCO X3 (Cobalt Blue, 8GB RAM, 128GB Storage)', description: 'Poco X3 with powerful performance and great features', price: 299 },
  { id: 28, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/71b0T9mkV3L._AC_UY327_FMwebp_QL65_.jpg', title: 'POCO M6 5G (Galactic Black, 8GB RAM, 256GB Storage)', description: 'Upgraded Poco X3 with smoother performance', price: 329 },
  { id: 29, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/61C0ewGxKJL._AC_UY327_FMwebp_QL65_.jpg', title: 'Acer Aspire Lite 12th Gen Intel Core i7-1255U Premium Metal Laptop', description: 'Versatile laptop suitable for various needs', price: 549 },
  { id: 30, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/91xnO7qHAeL._AC_UY327_FMwebp_QL65_.jpg', title: 'Panasonic LUMIX G7 16.00 MP 4K Mirrorless Interchangeable Lens Camera Kit with 14-42 mm Lens (Black) with 3x Optical Zoom', description: '4K Ultra HD camera for stunning visuals', price: 1199 },
  { id: 31, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/81XXj6IKM8L._AC_UY327_FMwebp_QL65_.jpg', title: 'Samsung Galaxy M35 5G (Thunder Grey,6GB RAM,128GB Storage)', description: 'Latest Samsung mobiles with innovative technology', price: 499 },
  { id: 32, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/71C6TFutbeL._AC_UY327_FMwebp_QL65_.jpg', title: 'Video Camera Camcorder kimire Digital Camera Recorder Full HD 1080P', description: 'High-quality video camera for recording events', price: 699 },
  { id: 33, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/41yHIEqovQL._AC_UY327_FMwebp_QL65_.jpg', title: 'Lenovo V15 AMD Ryzen 3 7320U 15.6" (39.62cm) FHD 250 Nits Antiglare Thin and Light Laptop (8GB/512GB SSD/Windows 11', description: 'Lenovo laptop with enhanced performance', price: 749 },
  { id: 34, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/61LB+d0vheL._AC_UY327_FMwebp_QL65_.jpg', title: 'OnePlus Nord CE 3 Lite 5G (Chromatic Gray, 8GB RAM, 128GB Storage)', description: 'OnePlus mobile phones with state-of-the-art features', price: 699 },
  { id: 35, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/61IJYdwddlL._AC_UY327_FMwebp_QL65_.jpg', title: 'insta360 GO 3S - 128 GB White 4K Tiny Portable Vlogging Camera', description: 'High-performance cameras for professional shooting', price: 1299 },
  { id: 36, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/719C6bJv8jL._AC_UY327_FMwebp_QL65_.jpg', title: 'Apple 2022 MacBook Air laptop with M2 chip: 34.46 cm (13.6-inch) Liquid Retina display', description: 'Premium MacBook with advanced capabilities', price: 1399 },
  { id: 37, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/5155PFA2N+L._AC_UY327_FMwebp_QL65_.jpg', title: 'OnePlus Nord CE4 Lite 5G (Mega Blue, 8GB RAM, 128GB Storage)', description: 'OnePlus mobile with latest specifications', price: 549 },
  { id: 38, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/711seEoUM9L._AC_UY327_FMwebp_QL65_.jpg', title: 'KODAK EKTAR H35 Half Frame Film Camera, 35mm, Reusable, Focus-Free, Lightweight, Easy-to-Use (Sage) (Film & AAA Battery are not Included)', description: 'Classic film camera for traditional photography', price: 499 },
  { id: 39, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/610pMwN1z6L._AC_UY327_FMwebp_QL65_.jpg', title: 'g Laptop (16GB RAM/512GB SSD/4GB)', description: 'Acer laptop with robust performance', price: 599 },
  { id: 40, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/61Io5-ojWUL._AC_UY327_FMwebp_QL65_.jpg', title: 'OnePlus Nord CE4 Lite 5G (Super Silver, 8GB RAM, 128GB Storage)', description: 'High-quality OnePlus mobiles with advanced features', price: 299 },
  { id: 41, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/71c-jiE2IcL._AC_UY327_FMwebp_QL65_.jpg', title: 'Lecran Digital Camera, FHD 1080P Kids Camera with 32GB Card, 2 Batteries', description: 'Leica camera for professional photography', price: 799 },
  { id: 42, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/61qlqvTsocL._AC_UY327_FMwebp_QL65_.jpg', title: 'Acer Aspire 3 Laptop Intel Core Celeron N4500 Processor Laptop ', description: 'Reliable Acer laptop for everyday use', price: 499 },
  { id: 43, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/71VW8LmqqPL._AC_UY327_FMwebp_QL65_.jpg', title: 'Redmi Note 13 5G (Arctic White, 6GB RAM, 128GB Storage) | 5G Ready', description: 'Latest Realme mobile with excellent performance', price: 399 },
  { id: 44, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/61Q4lRPtMdL._AC_UY327_FMwebp_QL65_.jpg', title: 'HMD Crest Max 5G | Segment 1st 50 MP Front Cam | Triple Rear AI Cam with 64 MP Primary Sony Sensor ', description: 'Vivo Y200 Pro with high-resolution display', price: 349 },
  { id: 45, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/81d4ZCaBnsL._AC_UY327_FMwebp_QL65_.jpg', title: 'Lenovo IdeaPad Slim 5 12th Gen Intel Core i5 12450H', description: 'Slim and powerful Lenovo Ideapad i5', price: 699 },
  { id: 46, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/61xl4inLS5L._AC_UY327_FMwebp_QL65_.jpg', title: 'Focusify Digital Camera, 4K Ultra HD Cameras for Photography, Digital', description: 'Leica camera for professional photography', price: 799 },
  { id: 47, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/71scmEdSC2L._AC_UY327_FMwebp_QL65_.jpg', title: 'Redmi 13C (Starfrost White, 6GB RAM, 128GB Storage)', description: 'High-end mobiles with cutting-edge technology', price: 499 },
  { id: 48, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/61emNBHv5uL._AC_UY327_FMwebp_QL65_.jpg', title: 'SKYVIK SIGNI One Mobile Camera 60mm Telephoto Lens Kit for Portrait Photography Black', description: 'Versatile camera lens for creative shots', price: 249 },
  { id: 49, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/71jG+e7roXL._AC_UY327_FMwebp_QL65_.jpg', title: 'Apple MacBook Air Laptop M1 chip, 13.3-inch/33.74 cm Retina Display, 8GB RAM, 256GB SSD Storage', description: 'Windows software for enhanced productivity', price: 129 },
  { id: 50, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/61oTT4XOSxL._AC_UY327_FMwebp_QL65_.jpg', title: 'Pixel 7 Pro 5G (Obsidian, 12GB RAM, 128GB Stoarge)', description: 'Google Pixel mobile with advanced features', price: 899 },
  { id: 51, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/61wsvCGXqnL._AC_UY327_FMwebp_QL65_.jpg', title: 'GoPro Hero12 Waterproof Action Camera with Front&Rear LCD Screens,5.3K60 Ultra Hd Video', description: 'Compact camera perfect for travel photography', price: 399 },
  { id: 52, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/71UEoJcmA7L._AC_UY327_FMwebp_QL65_.jpg', title: 'ASUS TUF Gaming A15, AMD Ryzen 7 7435HS, 15.6-inch FHD 144Hz, Gaming Laptop', description: 'Windows software for enhanced productivity', price: 129 },
  { id: 53, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/61l9ppRIiqL._AC_UY327_FMwebp_QL65_.jpg', title: 'Apple iPhone 13 (128GB) - Pink', description: 'Apple iPhone 12 with top-notch features', price: 999 },
  { id: 54, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/51eVdwd8vgL._AC_UY327_FMwebp_QL65_.jpg', title: 'Eyetech 360 SmartEye Mini Wireless WiFi Security Camera | HD 1080p ', description: 'Portable camera for on-the-go photography', price: 349 },
  { id: 55, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/81T5jRKDkvL._AC_UY327_FMwebp_QL65_.jpg', title: 'Dell 15 Thin & Light Laptop, Intel Core i5-1235U Processor/16GB DDR4/512GB ', description: 'High-performance Nisi laptops', price: 799 },
  { id: 56, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/61QrADCsn8L._AC_UY327_FMwebp_QL65_.jpg', title: 'Lava Blaze X 5G - Titanium Grey (4GB RAM | 128GB Storage)', description: 'Lava mobile with impressive specs', price: 299 },
  { id: 57, category: 'filter-app', img: 'https://m.media-amazon.com/images/I/61ZQmvzVJuL._AC_UY327_FMwebp_QL65_.jpg', title: 'LEQTRONIQ 4K HD Auto Focus Camera with 48MP 30FPS 18X Digital Zoom, Red', description: 'High-definition video shooting camera', price: 2999 },
  { id: 58, category: 'filter-product', img: 'https://m.media-amazon.com/images/I/51GGNToj7aL._AC_UY327_FMwebp_QL65_.jpg', title: 'Acer Nitro V Gaming Laptop 13th Gen Intel Core i5-13420H', description: 'Gaming laptop with powerful specs', price: 1499 },
  { id: 59, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/718RrecpkVL._AC_UY327_FMwebp_QL65_.jpg', title: 'vivo Y28e 5G (Vintage Red, 4GB RAM, 64GB Storage)', description: 'Vivo mobile with stylish design', price: 349 },
  { id: 60, category: 'filter-branding', img: 'https://m.media-amazon.com/images/I/61ZBxbrVF0L._AC_UY327_FMwebp_QL65_.jpg', title: 'vivo Y18e (Space Black, 4GB RAM, 64GB Storage) ', description: 'Vivo Y18 series with innovative features', price: 399 }
];
export default items;

