import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoginPage from './Login';
import shopnowlogo from '../../Media/Images/shopnowlogowhite.png';

export default class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showLogin: false,
            showNavMenu: false,
            showTopbar: false,
            activeSection: 'hero', // Default to 'hero' as active
        };
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        if (window.scrollY > 50) { // Adjust the scroll value as needed
            this.setState({ showTopbar: true });
        } else {
            this.setState({ showTopbar: false });
        }
    };

    togglePopup = () => {
        this.setState((prevState) => ({
            showLogin: !prevState.showLogin,
        }));
    };

    toggleNavMenu = () => {
        this.setState((prevState) => ({
            showNavMenu: !prevState.showNavMenu,
        }));
    };

    handleLinkClick = (sectionId, resetActive = false) => {
        if (resetActive) {
            this.setState({
                showNavMenu: false, // Close the menu
                activeSection: '',  // Reset the active section
            });
        } else {
            this.scrollToSection(sectionId);
            this.setState({
                showNavMenu: false, // Close the menu after clicking a link
                activeSection: sectionId, // Set the active section
            });
        }
    };

    scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    render() {
        const { showLogin, showNavMenu, showTopbar, activeSection } = this.state;

        return (
            <>
                <div className='index-page'>

                    {showLogin && (<LoginPage setShowLogin={this.togglePopup} />)}

                    <header id="header" className="header fixed-top">

                        <div className={`topbar d-flex align-items-center my-2 ${showTopbar ? 'show' : 'show'}`}>
                            <div className="container d-flex justify-content-center justify-content-md-between">
                                <div className="contact-info d-flex align-items-center">
                                    <i className="fas fa-envelope d-flex align-items-center">
                                        <a href="/">shopnow@gmail.com</a>
                                    </i>
                                    <i className="fas fa-phone-alt d-flex align-items-center ms-4">
                                        <span>+1 5589 55488 55</span>
                                    </i>
                                </div>
                                <div>
                                    <div className="social-links d-none d-md-flex align-items-center">
                                        <Link to='/wishlist' className="wishlist">
                                            <i className="fas fa-heart"></i>
                                        </Link>
                                        <Link to='/cart' className="cart">
                                            <i className="fas fa-shopping-cart"></i>
                                        </Link>
                                        <a href="#" className="login" onClick={this.togglePopup}>
                                            <i className="fas fa-user-circle"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* Navbar */}
                        <div className="branding py-3 d-flex align-items-center">
                            <div className="container position-relative d-flex align-items-center justify-content-between">
                                <Link to="/" className="logo d-flex align-items-center">
                                    <img src={shopnowlogo} alt="ShopNow Logo" />
                                </Link>

                                <i
                                    className={`mobile-nav-toggle d-xl-none ${showNavMenu ? 'bi bi-x' : 'bi bi-list'}`}
                                    onClick={this.toggleNavMenu}
                                >
                                    {showNavMenu ? 'Close' : <i className="fa-solid fa-bars"></i>}
                                </i>

                                <nav id="navmenu" className={`navmenu ${showNavMenu ? 'show' : ''}`}>
                                    <ul>
                                        <li>
                                            <Link
                                                to="/#hero"
                                                className={activeSection === 'hero' ? 'active' : ''}
                                                onClick={() => this.handleLinkClick('hero')}
                                            >
                                                Home
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/#products"
                                                className={activeSection === 'products' ? 'active' : ''}
                                                onClick={() => this.handleLinkClick('products')}
                                            >
                                                Products
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/#services"
                                                className={activeSection === 'services' ? 'active' : ''}
                                                onClick={() => this.handleLinkClick('services')}
                                            >
                                                Services
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                to="/#contact"
                                                className={activeSection === 'contact' ? 'active' : ''}
                                                onClick={() => this.handleLinkClick('contact')}
                                            >
                                                Contact
                                            </Link>
                                        </li>
                                    </ul>

                                    <div className="social-links d-md-none">
                                        <Link to='/wishlist' className="wishlist" onClick={() => this.handleLinkClick('', true)}>
                                            <i className="fas fa-heart"></i>
                                        </Link>
                                        <Link to='/cart' className="cart" onClick={() => this.handleLinkClick('', true)}>
                                            <i className="fas fa-shopping-cart"></i>
                                        </Link>
                                        <a href="#" className="login" onClick={() => {
                                            this.togglePopup();
                                            this.handleLinkClick('', true);
                                        }}>
                                            <i className="fas fa-user-circle"></i>
                                        </a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                    </header>
                </div>
            </>
        );
    }
}
